:root {
    --primary-color: #110135;
    --secondary-color: #eaeaf1;
    --highlight-color: #865aff;
}

@font-face {
    font-family: 'lucas-bold';
    src: url('./font/FSLucasTrial-Bold.otf') format('truetype');
}
@font-face {
    font-family: 'helvetica';
    src: url('./font/Helvetica.ttc') format('truetype');
}
@font-face {
    font-family: 'lucas-pro-regular';
    src: url('./font/FSLucasPro-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'lucas-pro-bold';
    src: url('./font/FSLucasPro-Bold.ttf') format('truetype');
}


#wrap {
    position: relative;
    width: 1920px;
    height: 946px;
    margin: auto;
    bottom: 0px;
}

#outer {
    position: relative;
    width: 1920px;
    height: 946px;
    -webkit-transform-origin: top left;
}

.ads {
    position: relative;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border: none;
    background: none;
}

html {
    background: none !important;
}

body {
    font-family: lucas-pro-regular !important;
    background: none !important;
    font-size: 14px;
    line-height: normal !important;
    animation: fadein 1s;
    -moz-animation: fadein 1s;
    /* Firefox */
    -webkit-animation: fadein 1s;
    /* Safari and Chrome */
    -o-animation: fadein 1s;
    /* Opera */
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-moz-keyframes fadein {
    /* Firefox */
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-webkit-keyframes fadein {
    /* Safari and Chrome */
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-o-keyframes fadein {
    /* Opera */
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


.offline-logo {
    height: 150px;
    margin-top: 60px;
}

.App {
    text-align: center;
}

.App-header {
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    position: absolute;
    z-index: 2;
    width: 100%;
    background-color: var(--primary-color);
    /* background-position: center; 
  background-repeat: no-repeat; 
  background-size: cover; */
}

.homeContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0;
}

.homeHeader {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.offline-col {
    color:#9aa0a6;
    text-align: center;
    padding-left: 20px !important;
    padding-right: 20px !important;
    background-color: white;
}

#accent-text {
    color: var(--primary-color);
}


.closeBanner {
    text-align: right;
    width: 100%;
    padding-right: 10px;
    margin-bottom: 5px;
    font-size: 35px;
    background-color: transparent;
}

.verify-email-modal-content {
    display: flex !important;
    align-items: center;
    justify-content: center;
}

#verify-row {
    text-align: center;
    margin-bottom: 20px;
}

.modal {
    display: flex !important;
    justify-content: center;
}

.modal-body {
    position: relative;
    padding: 0px !important;
}

.modal-dialog {
    width: auto !important;
    max-width: none !important;
    margin: 0px !important;
}

.verify-email-modal-body {
    padding: 0px;
}

.verify-email-text {
    text-align: center;
    color: grey;
}

.reload-page, .resend-email, .logout {
    color: white;
    background-color: var(--primary-color);
    border: none;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 10px;
    padding-left: 10px;
}

.resend-email{
    background-color: white;
    color: #865aff;
    border: solid #865aff 2px;
}

.reload-page{
    background-color: #865aff;
}

.verify-account-text{
    font-weight: 600;
    color: #865aff;
}

.logout{
    text-decoration: underline;
    color: rgb(128,128,128);
    background-color: rgba(0,0,0,0);
}