.skyscrapper{
    bottom: 0;
    right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 40px;

    // top: 5px;
    // right: 5px;
    position: absolute;
    z-index: 2;
    width: 160px;
    height: 600px;
    // background-color: green;
}

.box{
    bottom: 90px; 
    right: 160px; 
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 40px;

    // top: 95px; 
    // right: 165px; 
    position: absolute; 
    z-index: 2; 
    width: 200px; 
    height: 200px; 
    // background-color:red 
}

.leaderboard{
    bottom: 0;
    right: 160px; 
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 40px;

    // top: 5px;
    // right: 165px; 
    position: absolute; 
    z-index: 2; 
    width:728px;
    height:90px; 
    // background-color:blue;
}